export const VIDEO_SOURCES = {
    desktop: {
        src: 'https://fiverr-res.cloudinary.com/video/upload/v1/video-attachments/generic_asset/asset/18ad23debdc5ce914d67939eceb5fc27-1738830703211/Desktop%20Header%20new%20version',
        type: 'video/mp4',
    },
};

export const VIDEO_POSTERS = {
    desktop:
        'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/044630cc230d81edf3cc226212308295-1744042923926/hero.png',
};
