import { getContext } from '@fiverr-private/fiverr_context';
import { useBreakpoint, useIsClient } from '@fiverr-private/hooks';

type Breakpoint = 'default' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;

export const useIsMobileLayout = (thresholdBreakpoints: Breakpoint[] = ['default']) => {
    const { isMobile } = getContext();
    const breakpoint = useBreakpoint();
    const isClient = useIsClient();

    return isClient ? thresholdBreakpoints.includes(breakpoint) : isMobile;
};
