import React from 'react';
import { SafeComponent } from '@fiverr-private/orca';
import { Container } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import VideoHeroBanner from '../VideoHeroBanner';
import Hero from '../Hero';
import { useIsMobileLayout } from '../../../../shared/hooks/useIsMobileLayout';
import { inExperiment } from '../../../logged_out_homepage/utils/experiments';
import { LYNXES_LCP_LOHP_OPTIMIZATION } from '../../../logged_out_homepage/utils/experiments/experimentsEnum';

const VideoHeroBannerWrapper = () => {
  const isMobileLayout = useIsMobileLayout(['default', 'sm']);
  const { abTests } = getContext();
  const inOptimisationTest = inExperiment(LYNXES_LCP_LOHP_OPTIMIZATION, abTests);

  return (
    <>
      <Container display={{ default: 'none', md: 'block' }}>
        <VideoHeroBanner shouldLoadVideo={inOptimisationTest ? !isMobileLayout : true} />
      </Container>
      <Container display={{ default: 'block', md: 'none' }}>
        <Hero />
      </Container>
    </>
  );
};

export default SafeComponent(VideoHeroBannerWrapper);
